import {mapActions,mapMutations,mapGetters} from "vuex";
export default {
  name: "basket-info",
  data(){
    return{
    }
  },
  computed:{
    ...mapGetters({
      basket: 'basket/basket',
      currency:'setting/currentCurrency',
    }),
    disabler(){
      return this.basket.some(item=>{
        return item.state
      })
    },
    totalAmountOriginal() {
      return this.basket.reduce((accumulator, currentValue) => {
        return accumulator + ((currentValue.sale.new_price?currentValue.sale.new_price:currentValue.original_price) * currentValue.select_count);
      }, 0);
    },
    totalAmountCurrency() {
      return this.basket.reduce((accumulator, currentValue) => {
        return accumulator + ((currentValue.sale.new_price?currentValue.sale.new_price:currentValue.price) * currentValue.select_count);
      }, 0);
    },
    totalWeight(){
      return this.basket.reduce((accumulator, currentWeight) => {
        return accumulator + (currentWeight.dimensions.weight * currentWeight.select_count);
      }, 0);
    },
    totalCount(){
      return this.basket.reduce((accumulator, currentWeight) => {
        return accumulator +  currentWeight.select_count;
      }, 0);
    }
  },
  created() {
    console.log(this.basket);
  },
  methods:{
    submit(){
      this.$router.push({name: 'confirm-order'}).catch(() => {console.log()});
    }
  }
}